import { render, staticRenderFns } from "./Group.vue?vue&type=template&id=47650b70&"
import script from "./Group.vue?vue&type=script&setup=true&lang=js&"
export * from "./Group.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormError: require('/var/www/vhosts/youranastomosis.com/test.youranastomosis.com/components/Form/Error.vue').default})
