import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=49faa540&"
import script from "./ContactForm.vue?vue&type=script&lang=js&"
export * from "./ContactForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormTextInput: require('/var/www/vhosts/youranastomosis.com/test.youranastomosis.com/components/Form/TextInput.vue').default,FormGroup: require('/var/www/vhosts/youranastomosis.com/test.youranastomosis.com/components/Form/Group.vue').default,FormTextAreaInput: require('/var/www/vhosts/youranastomosis.com/test.youranastomosis.com/components/Form/TextAreaInput.vue').default})
